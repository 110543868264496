import { useMsal } from "@azure/msal-react";
import {
  Link,
  Divider,
} from "@skf-internal/ui-components-react";
import { useEffect, useState, useRef } from "react";
import LogoutButton from "./logout";

export interface IUserProps {
  auth: any;
}

export default function User(props: IUserProps) {
  const [showUser, setShowUser] = useState(false);

  const handleUserClick = () => {
    setShowUser(!showUser);
  };
  const [user, setUser] = useState<any>();
  const { accounts } = useMsal();

  useEffect(() => {
    if (accounts.length > 0) {
       setUser(accounts[0]);
    }
  }, [accounts]);
  return (
    <>
      <span id="userinfo">
        <span className="loggedin-user" title={user?.idTokenClaims?.email}>
          <Link
            as="button"
            feIcon={{
              feIcon: "chevronDown",
              position: "right",
            }}
            feColor="secondary"
            type="button"
            onClick={handleUserClick}
          >
            {user?.name}
          </Link>
        </span>

        {showUser && (
          <span id="userDropDown">
            <div className="user">
              <span className="name">{user?.name}</span>
              <span className="email" title={user?.idTokenClaims?.email}>{user?.idTokenClaims?.email}</span>
            </div>
            <Divider feColor="secondary" />
            <LogoutButton />
          </span>
        )}
      </span>
    </>
  );
}
