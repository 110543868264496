import  { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import SubHeader from "../components/SubHeader";

import HorizontalCard from "../components/HorizontalCard";
import { policies } from "../../policies";
import { InteractionStatus } from "@azure/msal-browser";
import { useNavigate } from "react-router-dom";
import { SettingsCard } from "../components/SettingsCard";

const UserProfile = () => {
  const { instance, accounts, inProgress } = useMsal();

  //to be developed
  const language = "en";
  const navigate = useNavigate();

  const [user, setUser] = useState<any>();

  useEffect(() => {
    // Kolla om det finns några konton (användare) inloggade
    if (accounts.length > 0) {
      // Hämta information om den första användaren (det kan finnas flera)
      setUser(accounts[0]);
    }
  }, [accounts]);

  /*const securityContent = (
    <ul>
      <li>Change your password</li>
      <li>Add a two-factor authentication</li>
    </ul>
  );*/
  const securityContent = (
    <ul>
      <li>Change your password</li>
    </ul>
  );

  const privacyContent = (
    <ul>
      <li>Link to privacy page</li>
      <li>Marketing consent</li>
      <li>What data we collect and why</li>
    </ul>
  );

  const handleProfileEdit = () => {
    const editProfileRequest: any = policies.authorities.editProfile;

    if (language !== "en") {
      editProfileRequest.extraQueryParameters = { ui_locales: language };
    }
    if (inProgress === InteractionStatus.None) {
      instance.acquireTokenRedirect(editProfileRequest);
    }
  };

  const handleDelete = () => {
    const deleteUserRequest: any = policies.authorities.deleteUser;
    if (language !== "en") {
      deleteUserRequest.extraQueryParameters = { ui_locales: language };
    }
    if (inProgress === InteractionStatus.None) {
      instance.acquireTokenRedirect(deleteUserRequest);
    }
  };

  const handlePrivacy = () => {
    window.open(process.env.REACT_APP_POLICY_WEB, "_blank");
  };
  return (
    <>
      <SubHeader title={ `Hello, ${user?.name}`} subContent={"Welcome to your SKF account."} />
      <section className="application-card-container">
       
            <SettingsCard
              icon="user"
              heading="My Profile"
              morelink={handleDelete}
              moretext="Delete account"
              secondLinkText="Update your personal details"
              secondLink={handleProfileEdit}
              user={user}
            />
        {user?.idTokenClaims?.idp == null && 
          <HorizontalCard
            icon="lock"
            heading="Security"
            content={securityContent}
            morelink={() => navigate("/SecurityInfo")}
            moretext="Open"
          />
        }
          <HorizontalCard
            icon="eye"
            heading="Privacy"
            content={privacyContent}
            morelink={() => navigate("/PrivacyInfo")}
            moretext="Open"
          />

  

         
      </section>
    </>
  );
};

export default UserProfile;
