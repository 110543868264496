import { useMsal } from "@azure/msal-react";
import "./App.css";

import { useEffect } from "react";
import PageLayout from "./features/components/PageLayout";
import { Route,  Routes } from "react-router-dom";
import UserProfile from "./features/pages/UserProfile";
import SecurityInfo from "./features/pages/SecurityInfo";
import PrivacyInfo from "./features/pages/PrivacyInfo";

function App() {
  const { instance, accounts } = useMsal();
  const handleSilentLogin = async () => {
    try {
      if (accounts.length > 0) {
        // Försök utföra tyst autentisering
        const silentRequest: any = {
          account: accounts[0],
        };
        const response = await instance.acquireTokenSilent(silentRequest);
    }
    } catch (error) {
    }
  };
  if (process.env.REACT_APP_ENVIRONMENT != "Production") {
    document.title = process.env.REACT_APP_ENVIRONMENT + " - My SKF account";
  } else {
    document.title = "My SKF account";
  }

  useEffect(() => {
    handleSilentLogin();
  }, []);

  return (
    <>
      <PageLayout>
        <Routes>
          <Route path="/" element={<UserProfile />} />
          <Route path="/securityinfo" element={<SecurityInfo />} />
          <Route path="/privacyinfo" element={<PrivacyInfo />} />
        </Routes>
      </PageLayout>
    </>
  );
}

export default App;
