import  { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import BackToOverviewLink from "../components/BackToOverview";
import HorizontalCard from "../components/HorizontalCard";
import SubHeader from "../components/SubHeader";
const PrivacyInfo = () => {
  const {  accounts } = useMsal();

  //to be developed
  const language = "en";

  const [user, setUser] = useState<any>();

  useEffect(() => {
    // Kolla om det finns några konton (användare) inloggade
    if (accounts.length > 0) {
      // Hämta information om den första användaren (det kan finnas flera)
      setUser(accounts[0]);
    }
  }, [accounts]);

  const profileInfoContent = (
    <>
      <p>
        At SKF, we value your privacy and are committed to protecting your
        personal information. In our Privacy Page, you can learn more about
        how we handle and safeguard your data. Additionally, you have the
        flexibility to adjust your privacy settings according to your
        preferences.
        <br />
      </p>

      <p>
        <br />
        Please explore the different sections of our Privacy Page to access
        all the necessary information and configure your privacy settings.
      </p>
    </>
  );

  const PrivacyPolicyContent = (
    <>
    <ul>
      <li>How we handle your data</li>
      <li>Contact information</li>
    </ul>
    </>
  )
  const TermsOfUseContent = (
    <>
    <ul>
      <li>Trademarks</li>
      <li>Copyright</li>
      <li>Warranties</li>
    </ul>
    </>
  )

  return (
    <>
      <SubHeader
        title="Privacy"
        subContent={profileInfoContent}
      />
 
      <BackToOverviewLink />
   
       <section className="application-card-container">
       <HorizontalCard
               icon="unscheduledAction"
              heading="Privacy Policy"
              // morelink={()=>  window.open(process.env.REACT_APP_POLICY_WEB, "_blank" ,'height=1180,width=820' ) }
              morelink={()=>  window.open(process.env.REACT_APP_POLICY_WEB, "_blank" ) }
              moretext="Open"
              content={PrivacyPolicyContent}
            />
             <HorizontalCard
              icon="paste"
              heading="Terms of use"
              // morelink={()=>  window.open(process.env.REACT_APP_POLICY_WEB, "_blank" ,'height=680,width=420' ) }
              morelink={()=>  window.open(process.env.REACT_APP_TERMS_COND, "_blank"  ) }
              moretext="Open"
              content={TermsOfUseContent}
            />
       </section>
         </>
  );
};

export default PrivacyInfo;
